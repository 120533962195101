import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import styled from '@emotion/styled';
import PC_BEAKPOINT from '../constants';

const BioContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1.6rem;
  padding: 0 0.8rem;

  @media (min-width: ${PC_BEAKPOINT}) {
    padding: 0;
  }
`;

const BioImageWrapper = styled.div`
  margin-right: 1.2rem;
`;

const BioDescriptionWrapper = styled.p`
  margin-bottom: 0;
`;

const Bio: React.FC = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `);

  const { author, social } = data.site.siteMetadata;

  return (
    <BioContainer>
      <BioImageWrapper>
        <Image
          fixed={data.avatar.childImageSharp.fixed}
          alt={author}
          imgStyle={{
            borderRadius: '50%',
            marginBottom: 0,
          }}
        />
      </BioImageWrapper>
      <BioDescriptionWrapper>
        Written by <strong>{author}</strong> who lives and works in Tokyo.{' '}
        <a
          href={`https://twitter.com/${social.twitter}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          You should follow him on Twitter
        </a>
      </BioDescriptionWrapper>
    </BioContainer>
  );
};

export default Bio;
