import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Bio from './Bio';
import { MarkdownRemarkEdge } from '../../types/graphql-types';
import PC_BEAKPOINT from '../constants';

const Posts = styled.div`
  margin-bottom: 0;
`;

const Post = styled.div`
  margin-bottom: 4rem;
`;

const PostHeader = styled.header`
  padding: 0 0.8rem;

  @media (min-width: ${PC_BEAKPOINT}) {
    padding: 0;
  }

  small {
    font-size: 1.4rem;
  }
`;

const PostBody = styled.div`
  padding: 0 0.8rem;

  @media (min-width: ${PC_BEAKPOINT}) {
    padding: 0;
  }
`;

const PostTitle = styled.h3`
  font-size: 2.1rem;
  line-height: 1.3;
  margin-bottom: 0.6rem;
`;

const TagLists = styled.ul`
  list-style: none;
  margin-bottom: 0.8rem;
  margin-left: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  li {
    margin-right: 0.8rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ThumbnailWrapper = styled.div`
  margin-bottom: 1.6rem;
`;

interface Props {
  posts: Array<MarkdownRemarkEdge>;
}

const PostList: React.FC<Props> = ({ posts }: Props) => {
  const data = useStaticQuery(graphql`
    query PostListBigQurey {
      dummy: file(relativePath: { eq: "dummy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 868, maxHeight: 300) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <div className="container">
      <header>
        <Bio />
      </header>

      {posts.length > 0 && (
        <Posts className="posts">
          {posts.map(({ node: post }) => (
            <Post className="post" key={post.id}>
              <Link to={post.fields.slug}>
                <ThumbnailWrapper>
                  <Image
                    fluid={
                      post.frontmatter.featuredImage
                        ? post.frontmatter.featuredImage.childImageSharp.fluid
                        : data.dummy.childImageSharp.fluid
                    }
                  />
                </ThumbnailWrapper>
              </Link>
              <PostHeader>
                <PostTitle>
                  <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
                </PostTitle>
                <small
                  css={css`
                    display: block;
                    margin-bottom: 0.2rem;
                  `}
                >
                  {post.frontmatter.date}
                </small>
                {post.frontmatter.tags.length > 0 && (
                  <TagLists>
                    {post.frontmatter.tags.map(tag => (
                      <li key={tag}>
                        <Link to={`/tags/${tag}/`}>
                          <small>#{tag}</small>
                        </Link>
                      </li>
                    ))}
                  </TagLists>
                )}
              </PostHeader>
              <PostBody>
                <div>
                  <p
                    css={css`
                      margin-bottom: 0.8rem;
                    `}
                  >
                    {post.frontmatter.description || post.excerpt}
                  </p>
                </div>
                <Link to={post.fields.slug}>Keep Reading →</Link>
              </PostBody>
            </Post>
          ))}
        </Posts>
      )}
    </div>
  );
};

export default PostList;
