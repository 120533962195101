import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import PC_BEAKPOINT from '../constants';
import { modeThemeStyled } from './theme';
import { PageContext } from '../context';

const PaginationNav = styled.nav`
  margin: 2.4rem 0 4rem;
`;

const Navitation = styled.div`
  padding: 0 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${PC_BEAKPOINT}) {
    padding: 0;
  }
`;

const NavbarItem = modeThemeStyled.div`
  transition: ${({ theme }): string => theme.transition};

  a {
    color: ${({ theme }): string => theme.linkColor};
    background-color: ${({ theme }): string => theme.buttonBgColor};
    padding: 0.4rem 1.6rem;
    display: block;
  }

  &.next {
    margin-left: auto;

    span {
      position: relative;
      left: 1rem;
    }
  }

  &.prev {
    margin-right: auto;

    span {
      position: relative;
      right: 0.5rem;
    }
  }
`;

type PaginationPageContext = PageContext;

type Props = {
  pageContext: PaginationPageContext;
};

const Pagination: React.FC<Props> = ({ pageContext }: Props) => {
  const { previousPagePath, nextPagePath } = pageContext;

  return (
    <PaginationNav className="pagination" role="navigation">
      <Navitation>
        {previousPagePath && (
          <NavbarItem className="prev">
            <Link to={previousPagePath} rel="prev">
              <span role="img" aria-label="previous">
                👈
              </span>
              Previous
            </Link>
          </NavbarItem>
        )}
        {nextPagePath && (
          <NavbarItem className="next">
            <Link to={nextPagePath} rel="next">
              Next
              <span role="img" aria-label="next">
                👉
              </span>
            </Link>
          </NavbarItem>
        )}
      </Navitation>
    </PaginationNav>
  );
};

export default Pagination;
