import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import Layout from '../components/Layout';
import PostList from '../components/PostList';
import PoplarList from '../components/PoplarList';
import Pagination from '../components/Pagination';
import getOnlyPublished from '../utils/functions';
import {
  SiteSiteMetadata,
  MarkdownRemarkConnection,
} from '../../types/graphql-types';
import { PageContext } from '../context';

const PoplarListWrapper = styled.div`
  margin-bottom: 3.2rem;
`;

const PoplarListTitle = styled.h3`
  font-size: 2.2rem;
  padding: 0 0.8rem;
`;

type BlogPageContext = PageContext;

interface Props {
  pageContext: BlogPageContext;
  data: {
    site: {
      siteMetadata: SiteSiteMetadata;
    };
    allMarkdownRemark: MarkdownRemarkConnection;
  };
  location: Location;
}

export const BlogTemplate: React.FC<Props> = ({
  data,
  pageContext,
  location,
}: Props) => {
  const allPosts = data.allMarkdownRemark.edges;
  const posts =
    process.env.NODE_ENV === 'production'
      ? getOnlyPublished(allPosts)
      : allPosts;
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle || ''}>
      <PostList posts={posts || []} />
      <Pagination pageContext={pageContext} />

      <PoplarListWrapper>
        <PoplarListTitle>Weekty Popular Posts</PoplarListTitle>
        <PoplarList />
      </PoplarListWrapper>
    </Layout>
  );
};

export default BlogTemplate;

export const pageQuery = graphql`
  query BlogQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            published
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1200, maxHeight: 630) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                resize(width: 1200, quality: 100) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;
