import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import getOnlyPublished from '../utils/functions';
import {
  PageViewsConnection,
  MarkdownRemarkEdge,
  MarkdownRemark,
} from '../../types/graphql-types';

const PoplarPostContainer = styled.div`
  padding: 0 0.8rem;
`;

const PoplarPostLists = styled.ul`
  list-style: outside decimal;
`;

interface MarkdownRemarkWithCount extends MarkdownRemark {
  count: number;
}

interface MarkdownRemarkEdgeExtended extends MarkdownRemarkEdge {
  node: MarkdownRemarkWithCount;
}

const PoplarList: React.FC = () => {
  const data = useStaticQuery(graphql`
    query allPageViews {
      allPageViews {
        edges {
          node {
            totalCount
            id
          }
        }
      }
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 2000
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              published
            }
          }
        }
      }
    }
  `);

  const allPosts = data.allMarkdownRemark.edges;
  const posts: Array<MarkdownRemarkEdgeExtended> =
    process.env.NODE_ENV === 'production'
      ? getOnlyPublished(allPosts)
      : allPosts;
  const appPageViews: PageViewsConnection = data.allPageViews;
  const results: Array<MarkdownRemarkWithCount> = [];
  appPageViews.edges
    .sort(({ node: a }, { node: b }) => b.totalCount - a.totalCount)
    .filter(({ node }) => node.id !== '/')
    .slice(0, 5)
    .forEach(({ node }): void => {
      posts.forEach(({ node: post }) => {
        if (post.fields.slug === node.id) {
          results.push({
            count: node.totalCount,
            ...post,
          });
        }
      });
    });

  return (
    <PoplarPostContainer>
      {results.length > 0 && (
        <PoplarPostLists>
          {results.map(result => (
            <li key={result.id}>
              <Link to={result.fields.slug}>
                {result.frontmatter.title}: {result.count} point
              </Link>
            </li>
          ))}
        </PoplarPostLists>
      )}
    </PoplarPostContainer>
  );
};

export default PoplarList;
